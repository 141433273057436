import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportCourseCSV } from "../../appRedux/actions/courses";
import { apiURL } from "../../utils/config";
function DownloadCSV() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { queryInfo } = useSelector((state) => ({
    queryInfo: state.courses.queryInfo,
  }));
  const handleDownload = async () => {
    setLoader(true);
    // Step 1: Fetch CSV data from the API
    dispatch(
      exportCourseCSV({
        ...queryInfo,
        page: undefined,
        size: undefined,
      })
    )
      .then((data) => {
        // Step 2: Create a Blob from the CSV data
        const url = window.URL.createObjectURL(
          new Blob([data], { type: "text/csv" })
        );
        // Step 3: Create a link to download the Blob
        const a = document.createElement("a");
        a.href = url;
        a.download = "course.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();

        // Revoke the object URL after the download is complete
        window.URL.revokeObjectURL(url);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
    // const response = await fetch("https://staging-backend.choicedekho.com/api/v1/exportData", {
    //   headers: {
    //     Accept: "text/csv",
    //   },
    // });
    // const csvData = await response.text();

    // // Step 2: Create a Blob from the CSV data
    // const blob = new Blob([csvData], { type: "text/csv" });

    // // Step 3: Create a link to download the Blob
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "course.csv";
    // document.body.appendChild(a);
    // a.click();
    // a.remove();
  };

  return (
    <Fragment>
      <a
        className="btn add-btn  m-r-5"
        onClick={() => (loader ? "" : handleDownload())}
        // href={`${apiURL}/v1/exportData`}
      >
        {loader ? (
          <i class="fas fa-spinner fa-spin me-2" />
        ) : (
          <i className="fas fa-file-export" />
        )}{" "}
        Export
      </a>
    </Fragment>
  );
}

export default DownloadCSV;
